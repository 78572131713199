export default {
  //  result page
  'result.success.title': 'Submission Success',
  'result.success.description':
        'The submission results page is used to feed back the results of a series of operational tasks. If it is a simple operation, use the Message global prompt feedback. This text area can show a simple supplementary explanation. If there is a similar requirement for displaying “documents”, the following gray area can present more complicated content.',
  'result.success.operate-title': 'Project Name',
  'result.success.operate-id': 'Project ID',
  'result.success.principal': 'Principal',
  'result.success.operate-time': 'Effective time',
  'result.success.step1-title': 'Create project',
  'result.success.step1-operator': 'Kirk Lin',
  'result.success.step2-title': 'Departmental preliminary review',
  'result.success.step2-operator': 'Zev Zhu',
  'result.success.step2-extra': 'Urge',
  'result.success.step3-title': 'Financial review',
  'result.success.step4-title': 'Finish',
  'result.success.btn-return': 'Back List',
  'result.success.btn-project': 'View Project',
  'result.success.btn-print': 'Print',
  'result.fail.error.title': 'Submission Failed',
  'result.fail.error.description':
        'Please check and modify the following information before resubmitting.',
  'result.fail.error.hint-title': 'The content you submitted has the following error:',
  'result.fail.error.hint-text1': 'Your account has been frozen',
  'result.fail.error.hint-btn1': 'Thaw immediately',
  'result.fail.error.hint-text2': 'Your account is not yet eligible to apply',
  'result.fail.error.hint-btn2': 'Upgrade immediately',
  'result.fail.error.btn-text': 'Return to modify',

  'profile.basic.orderDetailTitle': 'Order Details',
  'profile.basic.orderNumber': 'Order Number',
  'profile.basic.orderStatus': 'Order Status',
  'profile.basic.orderStatusValue': 'Finished',
  'profile.basic.transactionNumber': 'Transaction Number',
  'profile.basic.subOrderNumber': 'Suborder Number',
  'profile.basic.customerInfoTitle': 'Customer Information',
  'profile.basic.customerName': 'Customer Name',
  'profile.basic.contactNumber': 'Contact Number',
  'profile.basic.deliveryMethod': 'Delivery Method',
  'profile.basic.deliveryAddress': 'Delivery Address',
  'profile.basic.remarks': 'Remarks',
  'profile.basic.productInfoTitle': 'Product Information',
  'profile.basic.productName': 'Product Name',
  'profile.basic.unitPrice': 'Unit Price',
  'profile.basic.quantity': 'Quantity',
  'profile.basic.subtotal': 'Subtotal',
  'profile.basic.paymentInfoTitle': 'Payment Information',
  'profile.basic.paymentMethod': 'Payment Method',
  'profile.basic.paymentTime': 'Payment Time',
  'profile.basic.paymentAmount': 'Payment Amount',
  'profile.basic.paymentStatus': 'Payment Status',

  'profile.advanced.creater': 'Creator',
  'profile.advanced.create-time': 'Creation time',
  'profile.advanced.create-effective-date': 'effective date',
  'profile.advanced.create-product': 'product',
  'profile.advanced.create-id': 'Product documents',
  'profile.advanced.create-info': 'memo',
  'profile.advanced.create-status': 'Status',
  'profile.advanced.create-status-finshed': 'Finished',
  'profile.advanced.create-price': 'Price',
  'profile.advanced.create-do1': 'Opt1',
  'profile.advanced.create-do2': 'Opt1',
  'profile.advanced.create-do3': 'Opt1',
  'profile.advanced.tab1': 'detail',
  'profile.advanced.tab2': 'rules',
  'profile.advanced.card': 'Customer Card',
  'profile.advanced.id': 'ID card',
  'profile.advanced.group': 'Data Group',
  'profile.advanced.group-data': 'Data ID',
  'profile.advanced.group-data-update': 'Update Time',
  'profile.advanced.call-log': 'Contact records',
  'profile.advanced.call-spent': 'Contact duration',
  'profile.advanced.call-date': 'Contact Date',
  'profile.advanced.log': 'log1',
  'profile.advanced.log1': 'log1',
  'profile.advanced.log2': 'log3',
  'profile.advanced.log-type': 'type',
  'profile.advanced.log-owner': 'operator',
  'profile.advanced.log-result': 'result',
  'profile.advanced.log-time': 'start time',
  'profile.advanced.log-info': 'memo',
  'profile.advanced.remove': 'remove',
  'profile.advanced.step-title': 'progress',
  'profile.advanced.step-notice': 'Remind',

  'form.basic-form.basic.title': 'Basic form',
  'form.basic-form.basic.description':
      'Form pages are used to collect or verify information to users, and basic forms are common in scenarios where there are fewer data items.',
  'form.basic-form.title.label': 'Title',
  'form.basic-form.title.placeholder': 'Give the target a name',
  'form.basic-form.title.required': 'Please enter a title',
  'form.basic-form.date.label': 'Start and end date',
  'form.basic-form.placeholder.start': 'Start date',
  'form.basic-form.placeholder.end': 'End date',
  'form.basic-form.date.required': 'Please select the start and end date',
  'form.basic-form.goal.label': 'Goal description',
  'form.basic-form.goal.placeholder': 'Please enter your work goals',
  'form.basic-form.goal.required': 'Please enter a description of the goal',
  'form.basic-form.standard.label': 'Metrics',
  'form.basic-form.standard.placeholder': 'Please enter a metric',
  'form.basic-form.standard.required': 'Please enter a metric',
  'form.basic-form.client.label': 'Client',
  'form.basic-form.label.tooltip': 'Target service object',
  'form.basic-form.client.placeholder':
      'Please describe your customer service, internal customers directly {\'@\'}Name / job number',
  'form.basic-form.client.required': 'Please describe the customers you serve',
  'form.basic-form.invites.label': 'Inviting critics',
  'form.basic-form.invites.placeholder':
      'Please direct {\'@\'}Name / job number, you can invite up to 5 people',
  'form.basic-form.weight.label': 'Weight',
  'form.basic-form.weight.placeholder': 'Please enter weight',
  'form.basic-form.public.label': 'Target disclosure',
  'form.basic-form.label.help': 'Customers and invitees are shared by default',
  'form.basic-form.radio.public': 'Public',
  'form.basic-form.radio.partially-public': 'Partially public',
  'form.basic-form.radio.private': 'Private',
  'form.basic-form.publicUsers.placeholder': 'Open to',
  'form.basic-form.option.A': 'Colleague A',
  'form.basic-form.option.B': 'Colleague B',
  'form.basic-form.option.C': 'Colleague C',
  'form.basic-form.email.required': 'Please enter your email!',
  'form.basic-form.email.wrong-format': 'The email address is in the wrong format!',
  'form.basic-form.userName.required': 'Please enter your userName!',
  'form.basic-form.password.required': 'Please enter your password!',
  'form.basic-form.password.twice': 'The passwords entered twice do not match!',
  'form.basic-form.strength.msg':
      'Please enter at least 6 characters and don\'t use passwords that are easy to guess.',
  'form.basic-form.strength.strong': 'Strength: strong',
  'form.basic-form.strength.medium': 'Strength: medium',
  'form.basic-form.strength.short': 'Strength: too short',
  'form.basic-form.confirm-password.required': 'Please confirm your password!',
  'form.basic-form.phone-number.required': 'Please enter your phone number!',
  'form.basic-form.phone-number.wrong-format': 'Malformed phone number!',
  'form.basic-form.verification-code.required': 'Please enter the verification code!',
  'form.basic-form.form.get-captcha': 'Get Captcha',
  'form.basic-form.captcha.second': 'sec',
  'form.basic-form.form.optional': ' (optional) ',
  'form.basic-form.form.submit': 'Submit',
  'form.basic-form.form.save': 'Save',
  'form.basic-form.email.placeholder': 'Email',
  'form.basic-form.password.placeholder': 'Password',
  'form.basic-form.confirm-password.placeholder': 'Confirm password',
  'form.basic-form.phone-number.placeholder': 'Phone number',
  'form.basic-form.verification-code.placeholder': 'Verification code',
  // account page
  'account.center.tags': 'tags',
  'account.cneter.team': 'team',
  'account.center.article': 'article',
  'account.center.application': 'application',
  'account.center.project': 'project',
  'account.center.posted': 'posted on',
  'account.center.activity-user': 'activity user',
  'account.center.new-user': 'new user',
  'account.center.updated': 'Updated a few minutes ago',

  'account.settings.basic-setting': 'Basic Setting',
  'account.settings.security-setting': 'Security Setting',
  'account.settings.account-setting': 'Account Binding',
  'account.settings.message-setting': 'New Message',
  'account.settings.form-email': 'email',
  'account.settings.form-name': 'nickname',
  'account.settings.form-region': 'nation/region',
  'account.settings.form-address': 'Street address',
  'account.settings.form-phoneNumber': 'phone number',
  'account.settings.form-desc': 'Personal profile',
  'account.settings.form-region-China': 'China',
  'account.settings.form-input-plac': 'please enter',
  'account.settings.form-select-plac': 'please select',
  'account.settings.form-rule-name': 'please enter nickname',
  'account.settings.form-rule-phoneNumber': 'Please enter phone number',
  'account.settings.form-rule-address': 'Please enter address',
  'account.settings.form-rule-region': 'please select',
  'account.settings.form-rule-email': 'please enter email',
  'account.settings.form-rule-desc': 'please enter profile',
  'account.settings.basic-avatar': 'avatar',
  'account.settings.basic-avatar.upload': 'Upload picture',
  'account.settings.form-submit': 'submit',

  'account.settings.security.account-password': 'Account password',
  'account.settings.security.account-password-desc': 'Current password strength: Strong',
  'account.settings.security.phone': 'Security phone',
  'account.settings.security.phone-desc': 'Attached phone: 131****8888',
  'account.settings.security.email': 'Spare email',
  'account.settings.security.email-desc': 'Bound email: ant**.com',
  'account.settings.security.MFA': 'MFA',
  'account.settings.security.MFA-desc': 'No MFA device is bound',
  'account.settings.modify': 'edit',
  'account.settings.security-problem': 'security problem',
  'account.settings.security-problem-desc': 'Have set',
  'account.settings.account.taobao': 'Bind Taobao',
  'account.settings.account.alipay': 'Bind Alipay',
  'account.settings.account.dingding': 'Bind Ding',
  'account.settings.account.not.bind': 'Currently not bound',
  'account.settings.account.bind': 'bind',
  'account.settings.message.title1': 'Other message',
  'account.settings.message.title2': 'System message',
  'account.settings.message.title3': 'To-do task',
  'account.settings.message.desc1': 'Messages from other users will be notified in the form of an internal message',
  'account.settings.message.desc2': 'Messages from other System will be notified in the form of an internal message',
  'account.settings.message.desc3': 'Messages from other task will be notified in the form of an internal message',
}
