export default {
  // LoginPage
  'pages.layouts.userLayout.title': 'Nanjing Chenye Platform',
  'pages.login.accountLogin.tab': 'Account Login',
  'pages.login.accountLogin.errorMessage': 'Incorrect username/password(admin/admin)',
  'pages.login.failure': 'Login failed, please try again!',
  'pages.login.success': 'Login successful!',
  'pages.login.username.placeholder': 'Please input Username',
  'pages.login.username.required': 'Please input your username!',
  'pages.login.password.placeholder': 'Please input your password',
  'pages.login.password.required': 'Please input your password!',
  'pages.login.phoneLogin.tab': 'Phone Login',
  'pages.login.phoneLogin.errorMessage': 'Verification Code Error',
  'pages.login.phoneNumber.placeholder': 'Phone Number',
  'pages.login.phoneNumber.required': 'Please input your phone number!',
  'pages.login.phoneNumber.invalid': 'Phone number is invalid!',
  'pages.login.captcha.placeholder': 'Verification Code',
  'pages.login.captcha.required': 'Please input verification code!',
  'pages.login.phoneLogin.getVerificationCode': 'Get Code',
  'pages.getCaptchaSecondText': 'sec(s)',
  'pages.login.rememberMe': 'Remember me',
  'pages.login.forgotPassword': 'Forgot Password ?',
  'pages.login.submit': 'Login',
  'pages.login.loginWith': 'Login with :',
  'pages.login.registerAccount': 'Register Account',
  'pages.login.tips': 'welcome to the system',
}
